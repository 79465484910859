export const getCORPID = ():string => {
    return (process.env.VUE_APP_CORPID as string).toString()
}
export const getDingDingAPPID = ():string => {
    return (process.env.VUE_APP_DINGDINGAPPID as string).toString()
}
export const getOrderAppId = ():string => {
    return (process.env.VUE_APP_ORDER_APPID as string).toString()
}
export const getOrderURL = ():string => {
    return (process.env.VUE_APP_ORDER_URL as string).toString()
}
export const getAppId = ():string => {
    return (process.env.VUE_APP_APPID as string).toString()
}
export const getAppUrl = ():string => {
    return (process.env.VUE_APP_APPURL as string).toString()
}
